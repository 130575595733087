<template>
  <div>
    <b-row class="mb-3">
      <b-col
        :xl="columnSize"
        :lg="1 + columnSize"
        :md="2 + columnSize"
      >
        <FormInputSelect
          v-model="selectedPerPage"
          :options="perPageOptions" 
          label="Items per page"
        />
      </b-col>
      <b-col
        :xl="12 - columnSize"
        :lg="11 - columnSize"
        :md="10 - columnSize"
      >
        <b-row>
          <slot name="button" />
        </b-row>
      </b-col>
    </b-row>
    <slot name="table" />
    <b-row class="d-md-flex mb-3 mt-3 mt-md-2 justify-content-between">
      <b-col
        md
        class="d-flex"
      >
        <div class="mx-auto mx-md-0 my-auto">
          Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} results
        </div>
      </b-col>
      <b-col
        md
        class="d-flex"
      >
        <b-pagination
          v-show="pagination.total"
          v-model="selectedPage"
          :total-rows="metaData.total"
          :per-page="Number(metaData.per_page)"
          hide-goto-end-buttons
          prev-text="Previous"
          next-text="Next"
          class="mx-auto mx-md-0 mt-2 mt-md-0 ms-md-auto mb-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormInputSelect from '@/components/FormInputSelect.vue';

export default {
  components: {
    FormInputSelect,
  },
  props: {
    metaData: {
      type: Object,
      required: true
    },
    columnSize: {
      type: Number,
      default: 2
    },
  },
  data() {
    return {
      selectedPage: 1,
      selectedPerPage: '10',
    }
  },
  computed: {
    pagination() {
      if (this.metaData) {
        return {
          from: Number(this.metaData.from) ?? 0,
          to: Number(this.metaData.to) ?? 0,
          total: Number(this.metaData.total)?? 0,
        }
      }
      return {
        from: 0,
        to: 0,
        total: 0,
      }
    },
    perPageOptions() {    
      return [
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
      ];
    },
  },
  watch: {
    selectedPage: {
      deep: true,
      immediate: false,
      handler(val) {
        this.$emit('page-change', val);
      },
    },
    selectedPerPage: {
      deep: true,
      immediate: false,
      handler(val) {
        this.selectedPage = 1;
        this.$emit('per-page-change', val);
      },
    },
    'metaData.last_page': {
      deep: true,
      immediate: false,
      handler(val) {
        if(this.selectedPage > val) {
          this.selectedPage = 1
        }
      },
    },
  },
}
</script>
