<template>
  <b-row>
    <div class="d-flex justify-content-between">
      <b-input-group>
        <b-form-input
          v-model="searchKeyword"
          class="form-control search-input"
          :placeholder="placeholder"
          @keydown.enter="doSearch"
        />
        <b-input-group-append>
          <div
            class="cancel-search-icon h-100 px-3"
            :class="searchKeyword ? 'd-flex' : 'd-none'"
            style="position: relative;"
            @click="doReset"
          >
            <i class="ri-close-line my-auto" />
          </div>
          <b-button 
            variant="outline-light text-primary"
            class="btn-input-append w-100"
            text
            @click="doSearch"
          >
            <i class="ri-search-line" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-row>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search Item'
    },
    resetTrigger: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      searchKeyword: '',
    }
  },
  watch: {
    resetTrigger: {
      immediate: false,
      handler() {
        this.doReset()
      }
    }
  },
  methods: {
    doReset() {
      this.searchKeyword = '';
      this.$emit('reset-search');
    },
    doSearch() {
      this.$emit('do-search', this.searchKeyword);
    }
  },
}
</script>
<style scoped>
.input-group>.input-group-append {
    flex: 0 0;
}
</style>
